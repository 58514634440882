import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Grid, Row, Col } from '@class101/ui';
import { Playground, Props } from 'docz';
import { Component as StyledCol } from './Col';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "layout"
    }}>{`Layout`}</h1>
    <p>{`Class101 UI는 기기나 뷰포트 크기가 증가함에 따라 12열이 적절하게 확대되는 반응형, 모바일 우선 유동 그리드 시스템을 지원합니다.`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`NOTE`}</strong></p>
      <ul parentName="blockquote">
        <li parentName="ul">{`v4부터는 flex 기반의 레이아웃을 제공합니다.`}</li>
        <li parentName="ul">{`v6부터는 margin 스타일을 제어하는 속성을 따로 지원하지 않습니다.`}</li>
      </ul>
    </blockquote>
    <h2 {...{
      "id": "grid-options"
    }}>{`Grid options`}</h2>
    <p>{`lg: >= 1024px `}<br />{`
sm: < 1013px`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <h3 {...{
      "id": "grid"
    }}>{`Grid`}</h3>
    <Props of={Grid} mdxType="Props" />
    <h3 {...{
      "id": "row"
    }}>{`Row`}</h3>
    <Props of={Row} mdxType="Props" />
    <h3 {...{
      "id": "col"
    }}>{`Col`}</h3>
    <Props of={Col} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <p>{`데스크탑(lg)에선 쌓이다가 모바일(sm)에선 수평으로 변하는 코드입니다. 브라우저 창의 가로를 늘렸다 줄였다 해보세요!`}</p>
    <Playground __position={3} __code={'<Row>\n  <StyledCol lg={1}>lg-1</StyledCol>\n  <StyledCol lg={1}>lg-1</StyledCol>\n  <StyledCol lg={1}>lg-1</StyledCol>\n  <StyledCol lg={1}>lg-1</StyledCol>\n  <StyledCol lg={1}>lg-1</StyledCol>\n  <StyledCol lg={1}>lg-1</StyledCol>\n  <StyledCol lg={1}>lg-1</StyledCol>\n  <StyledCol lg={1}>lg-1</StyledCol>\n  <StyledCol lg={1}>lg-1</StyledCol>\n  <StyledCol lg={1}>lg-1</StyledCol>\n  <StyledCol lg={1}>lg-1</StyledCol>\n  <StyledCol lg={1}>lg-1</StyledCol>\n</Row>\n<Row>\n  <StyledCol lg={8}>lg-8</StyledCol>\n  <StyledCol lg={4}>lg-4</StyledCol>\n</Row>\n<Row>\n  <StyledCol lg={4}>lg-4</StyledCol>\n  <StyledCol lg={4}>lg-4</StyledCol>\n  <StyledCol lg={4}>lg-4</StyledCol>\n</Row>\n<Row>\n  <StyledCol lg={6}>lg-6</StyledCol>\n  <StyledCol lg={6}>lg-6</StyledCol>\n</Row>'} __scope={{
      props,
      DefaultLayout,
      Grid,
      Row,
      Col,
      Playground,
      Props,
      StyledCol,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Row mdxType="Row">
    <StyledCol lg={1} mdxType="StyledCol">lg-1</StyledCol>
    <StyledCol lg={1} mdxType="StyledCol">lg-1</StyledCol>
    <StyledCol lg={1} mdxType="StyledCol">lg-1</StyledCol>
    <StyledCol lg={1} mdxType="StyledCol">lg-1</StyledCol>
    <StyledCol lg={1} mdxType="StyledCol">lg-1</StyledCol>
    <StyledCol lg={1} mdxType="StyledCol">lg-1</StyledCol>
    <StyledCol lg={1} mdxType="StyledCol">lg-1</StyledCol>
    <StyledCol lg={1} mdxType="StyledCol">lg-1</StyledCol>
    <StyledCol lg={1} mdxType="StyledCol">lg-1</StyledCol>
    <StyledCol lg={1} mdxType="StyledCol">lg-1</StyledCol>
    <StyledCol lg={1} mdxType="StyledCol">lg-1</StyledCol>
    <StyledCol lg={1} mdxType="StyledCol">lg-1</StyledCol>
  </Row>
  <Row mdxType="Row">
    <StyledCol lg={8} mdxType="StyledCol">lg-8</StyledCol>
    <StyledCol lg={4} mdxType="StyledCol">lg-4</StyledCol>
  </Row>
  <Row mdxType="Row">
    <StyledCol lg={4} mdxType="StyledCol">lg-4</StyledCol>
    <StyledCol lg={4} mdxType="StyledCol">lg-4</StyledCol>
    <StyledCol lg={4} mdxType="StyledCol">lg-4</StyledCol>
  </Row>
  <Row mdxType="Row">
    <StyledCol lg={6} mdxType="StyledCol">lg-6</StyledCol>
    <StyledCol lg={6} mdxType="StyledCol">lg-6</StyledCol>
  </Row>
    </Playground>
    <h2 {...{
      "id": "mobile-first"
    }}>{`Mobile first`}</h2>
    <p>{`mobile first로 설계되어 sm만 설정 되어 있을 경우 sm에서의 설정이 데스크탑(lg)에서도 적용됩니다.
반대로 lg만 설정할 경우 모바일(sm)에서는 한 셀이 12열을 모두 차지하게 됩니다.`}</p>
    <Playground __position={4} __code={'<Row>\n  <StyledCol sm={4}>sm-4</StyledCol>\n</Row>\n<Row>\n  <StyledCol lg={4}>lg-4</StyledCol>\n</Row>'} __scope={{
      props,
      DefaultLayout,
      Grid,
      Row,
      Col,
      Playground,
      Props,
      StyledCol,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Row mdxType="Row">
    <StyledCol sm={4} mdxType="StyledCol">sm-4</StyledCol>
  </Row>
  <Row mdxType="Row">
    <StyledCol lg={4} mdxType="StyledCol">lg-4</StyledCol>
  </Row>
    </Playground>
    <h2 {...{
      "id": "with-grid-container"
    }}>{`With grid container`}</h2>
    <p>{`이 Grid Container는 중앙 정렬되며, 최대 가로 크기(max-width)를 가집니다.`}</p>
    <Playground __position={5} __code={'<Grid>\n  <Row>\n    <StyledCol lg={12}>With grid container</StyledCol>\n  </Row>\n</Grid>'} __scope={{
      props,
      DefaultLayout,
      Grid,
      Row,
      Col,
      Playground,
      Props,
      StyledCol,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Grid mdxType="Grid">
    <Row mdxType="Row">
      <StyledCol lg={12} mdxType="StyledCol">With grid container</StyledCol>
    </Row>
  </Grid>
    </Playground>
    <h2 {...{
      "id": "responsive-mobile-and-desktop-example"
    }}>{`Responsive mobile and desktop example`}</h2>
    <Playground __position={6} __code={'<Row>\n  <StyledCol lg={8} sm={12}>\n    lg-4\n  </StyledCol>\n  <StyledCol lg={4} sm={6}>\n    lg-4\n  </StyledCol>\n</Row>\n<Row>\n  <StyledCol lg={4} sm={6}>\n    lg-4\n  </StyledCol>\n  <StyledCol lg={4} sm={6}>\n    lg-4\n  </StyledCol>\n  <StyledCol lg={4} sm={6}>\n    lg-4\n  </StyledCol>\n</Row>'} __scope={{
      props,
      DefaultLayout,
      Grid,
      Row,
      Col,
      Playground,
      Props,
      StyledCol,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Row mdxType="Row">
    <StyledCol lg={8} sm={12} mdxType="StyledCol">
      lg-4
    </StyledCol>
    <StyledCol lg={4} sm={6} mdxType="StyledCol">
      lg-4
    </StyledCol>
  </Row>
  <Row mdxType="Row">
    <StyledCol lg={4} sm={6} mdxType="StyledCol">
      lg-4
    </StyledCol>
    <StyledCol lg={4} sm={6} mdxType="StyledCol">
      lg-4
    </StyledCol>
    <StyledCol lg={4} sm={6} mdxType="StyledCol">
      lg-4
    </StyledCol>
  </Row>
    </Playground>
    <h2 {...{
      "id": "responsive-blog-example"
    }}>{`Responsive blog example`}</h2>
    <Playground __position={7} __code={'<Grid>\n  <Row>\n    <StyledCol lg={12} sm={12}>\n      Header\n    </StyledCol>\n  </Row>\n  <Row>\n    <StyledCol lg={4} sm={12}>\n      Side Menu\n    </StyledCol>\n    <StyledCol style={{ height: 300 }} lg={8} sm={12}>\n      Content\n    </StyledCol>\n  </Row>\n  <Row>\n    <StyledCol lg={12} sm={12}>\n      Footer\n    </StyledCol>\n  </Row>\n</Grid>'} __scope={{
      props,
      DefaultLayout,
      Grid,
      Row,
      Col,
      Playground,
      Props,
      StyledCol,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Grid mdxType="Grid">
    <Row mdxType="Row">
      <StyledCol lg={12} sm={12} mdxType="StyledCol">
        Header
      </StyledCol>
    </Row>
    <Row mdxType="Row">
      <StyledCol lg={4} sm={12} mdxType="StyledCol">
        Side Menu
      </StyledCol>
      <StyledCol style={{
            height: 300
          }} lg={8} sm={12} mdxType="StyledCol">
        Content
      </StyledCol>
    </Row>
    <Row mdxType="Row">
      <StyledCol lg={12} sm={12} mdxType="StyledCol">
        Footer
      </StyledCol>
    </Row>
  </Grid>
    </Playground>
    <h2 {...{
      "id": "offset"
    }}>{`offset`}</h2>
    <Playground __position={8} __code={'<Grid>\n  <Row>\n    <StyledCol sm={8} smOffset={2} lgOffset={0}>\n      sm-8, sm-offset-2\n      <br />\n      lg-offset-8\n    </StyledCol>\n  </Row>\n  <Row>\n    <StyledCol lg={4} lgOffset={8}>\n      lg-4, lg-offset-8\n    </StyledCol>\n  </Row>\n  <Row>\n    <StyledCol lg={4} sm={8} lgOffset={4} smOffset={2}>\n      lg-4, lg-offset-4\n      <br />\n      sm-8, sm-offset-2\n    </StyledCol>\n  </Row>\n  <Row>\n    <StyledCol lg={2}>\n      lg-2\n      <br />\n    </StyledCol>\n    <StyledCol lg={2} lgOffset={2}>\n      lg-2\n      <br />\n      lg-offset-2\n    </StyledCol>\n    <StyledCol lg={2} lgOffset={2}>\n      lg-2\n      <br />\n      lg-offset-2\n    </StyledCol>\n  </Row>\n  <Row>\n    <StyledCol lg={10} lgOffset={2}>\n      lg-10, lg-offset-2\n    </StyledCol>\n  </Row>\n</Grid>'} __scope={{
      props,
      DefaultLayout,
      Grid,
      Row,
      Col,
      Playground,
      Props,
      StyledCol,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Grid mdxType="Grid">
    <Row mdxType="Row">
      <StyledCol sm={8} smOffset={2} lgOffset={0} mdxType="StyledCol">
        sm-8, sm-offset-2
        <br />
        lg-offset-8
      </StyledCol>
    </Row>
    <Row mdxType="Row">
      <StyledCol lg={4} lgOffset={8} mdxType="StyledCol">
        lg-4, lg-offset-8
      </StyledCol>
    </Row>
    <Row mdxType="Row">
      <StyledCol lg={4} sm={8} lgOffset={4} smOffset={2} mdxType="StyledCol">
        lg-4, lg-offset-4
        <br />
        sm-8, sm-offset-2
      </StyledCol>
    </Row>
    <Row mdxType="Row">
      <StyledCol lg={2} mdxType="StyledCol">
        lg-2
        <br />
      </StyledCol>
      <StyledCol lg={2} lgOffset={2} mdxType="StyledCol">
        lg-2
        <br />
        lg-offset-2
      </StyledCol>
      <StyledCol lg={2} lgOffset={2} mdxType="StyledCol">
        lg-2
        <br />
        lg-offset-2
      </StyledCol>
    </Row>
    <Row mdxType="Row">
      <StyledCol lg={10} lgOffset={2} mdxType="StyledCol">
        lg-10, lg-offset-2
      </StyledCol>
    </Row>
  </Grid>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      